import React from "react";
import { Wrapper } from "./styles";
import Loader from "react-loader-spinner";

export default function Loading() {
  return (
    <Wrapper>
      <Loader
        type="Rings"
        color="gray"
        height={100}
        width={100}
        timeout={30000} //3 secs
      />
    </Wrapper>
  );
}
