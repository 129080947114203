const defaultValue = {
  contactDescription: `
  <p>
    For now, I do remote working from my
    city and work with my own equipment. (my office gave me
    a laptop and I use it for backup plan if the power goes out)
  </p>
  <i>
    (This map below is not exactly represented where I live. but I
    live around it.)
  </i>`,
  contactLocation: "Around Juanda International Airport, Surabaya 61253",
  contactEmail: "diardomarendikrista@gmail.com",
  contactPhone: "Please send mail",
};

export default function contactReducer(state = defaultValue, action) {
  switch (action.type) {
    case "SET_CONTACT_DESCRIPTION":
      return {
        ...state,
        contactDescription: action.data,
      };
    default:
      return state;
  }
}
