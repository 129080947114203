import { combineReducers } from "redux";
import homeReducer from "./homeReducer";
import aboutReducer from "./aboutReducer";
import portfolioReducer from "./portfolioReducer";
import resumeReducer from "./resumeReducer";
import contactReducer from "./contactReducer";
import userReducer from "./userReducer";

const reducer = combineReducers({
  home: homeReducer,
  about: aboutReducer,
  portfolio: portfolioReducer,
  resume: resumeReducer,
  contact: contactReducer,
  user: userReducer,
});

export default reducer;
