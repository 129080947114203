const defaultValue = {
  about: `<p>A person who passionate about software development, technology,
  and general knowledge. Spend almost all of my time in front of my
  computer because I love to browsing and explore.</p>
<p>
  Graduated from Hacktiv8 Coding Bootcamp, made me achieve my dream
  as a developer. Now, I am a frontend developer on Klob (PT.
  Dayalima Family), building several projects from zero with my
  great team on Dayalima Family, and maintaining Klob website as
  well.
</p>`,
  loading: false,
  error: false,
};

export default function homeReducer(state = defaultValue, action) {
  switch (action.type) {
    case "SET_ABOUT":
      return {
        ...state,
        about: action.data,
      };
    case "LOADING_DETAIL_PROGRAM":
      return {
        ...state,
        loading: action.data,
      };
    case "ERROR_DETAIL_PROGRAM":
      return {
        ...state,
        error: action.data,
      };
    default:
      return state;
  }
}
