const defaultValue = {
  fullName: "Diardo M Krista",
  subtitle: [
    { id: 1, value: "Frontend Developer" },
    { id: 2, value: "Tech & PC Enthusiast" },
    { id: 3, value: "My Family's Tech Support =D" },
  ],
  showPhone: false,
  showNavbar: false,
  loading: false,
  error: false,
};

export default function homeReducer(state = defaultValue, action) {
  switch (action.type) {
    case "SET_FULLNAME":
      return {
        ...state,
        fullName: action.data,
      };
    case "SET_SUBTITLE":
      return {
        ...state,
        subtitle: action.data,
      };
    case "SHOW_PHONE":
      return {
        ...state,
        showPhone: action.data,
      };
    case "SHOW_NAVBAR":
      return {
        ...state,
        showNavbar: action.data,
      };
    case "LOADING_DETAIL_PROGRAM":
      return {
        ...state,
        loading: action.data,
      };
    case "ERROR_DETAIL_PROGRAM":
      return {
        ...state,
        error: action.data,
      };
    default:
      return state;
  }
}
