const defaultValue = {
  resume:
    "This is my quick resume, you can ask for my full personal resume/CV via Whatsapp (quick response), Email, or Linkedin.",
  summaries: [
    {
      position: "Diardo Marendi Krista",
      period: "",
      start: "",
      end: "",
      current: false,
      company: `Friendly, innovative and deadline-driven frontend developer.
      Graduated from one of the best coding bootcamp in Indonesia,
      and experienced in making website from zero, or using builder`,
      jobdesc: [
        "Surabaya - Jawa Timur, 61253",
        "diardomarendikrista@gmail.com",
      ],
    },
  ],
  educations: [
    {
      position: "Fullstack Javascript Developer",
      period: "2020 - 2021",
      start: "01-01-2020",
      end: "01-01-2021",
      current: false,
      company: "Hacktiv8 Coding Bootcamp, Jakarta (100.83 / 105)",
      jobdesc: [
        `Learn and gain fullstack javascript developer skill using React,
        Vue, Node JS, Graphql, etc framework, and implement it to web
        application`,
      ],
    },
    {
      position: "Informatic Management",
      period: "2009 - 2011",
      start: "01-01-2009",
      end: "01-01-2011",
      current: false,
      company: "Universitas Negeri Surabaya, Surabaya (3.43 / 4.00)",
      jobdesc: [
        `Learn programming using PHP native, Visual Basic, and Delphi. my
        thesis is about a application for worship place.`,
      ],
    },
  ],
  experiences: [
    {
      position: "Frontend Developer",
      period: "Jun 2021 - Present",
      start: "01-06-2021",
      end: "",
      current: true,
      company: "PT. Daya Dimensi Indonesia (klob.id), Jakarta, ID ",
      jobdesc: [
        "Slicing a design from figma, adobe XD, or zepplin using react",
        "Integrating API with backend, and make sure for good user experience",
        "Invent a custom UI with team, check at: <a href='https://www.npmjs.com/package/klob-ui-components' target='_blank'>Klob UI Components</a>",
        "Doing custom feature as per product owner's need",
        "Using github and AWS CodeCommit for repositories, and AWS S3 for bucket",
        "Using Jira for Agile project management system.",
      ],
    },
    {
      position: "Cargo Officer",
      period: "Jun 2018 - Nov 2020",
      start: "01-06-2018",
      end: "01-11-2020",
      current: false,
      company: "PT. Orient Air Services, Surabaya, ID",
      jobdesc: [
        "Malaysia Airlines Cargo",
        "Handling customer & agent for reservation and approach them to book our products.",
        "Handling office administration",
      ],
    },
    {
      position: "Admin and Tax",
      period: "Jan 2017 - May 2018",
      start: "01-01-2017",
      end: "01-05-2018",
      current: false,
      company: "PT. Borneo Bumi Sakti, Surabaya, ID",
      jobdesc: [
        "Handling all of administration and general tax for this company.",
      ],
    },
    {
      position: "Field Supervisor",
      period: "Aug 2015 - Dec 2016",
      start: "01-08-2015",
      end: "01-12-2016",
      current: false,
      company: "CV. Bangun Krista Pratama, Surabaya, ID",
      jobdesc: [
        "Supervise the field team to be on time from deadlines, and ensure the job is done properly.",
      ],
    },
    {
      position: "Passenger Service Leader",
      period: "Jan 2012 - Jul 2015",
      start: "01-01-2012",
      end: "01-07-2015",
      current: false,
      company: "PT Jasa Angkasa Semesta. Tbk, Surabaya, ID",
      jobdesc: [
        "Ensure passenger handling do their work smoothly from check-in area until boarding area.",
        "Develop new methods to make the job easier.",
      ],
    },
  ],
};

export default function resumeReducer(state = defaultValue, action) {
  switch (action.type) {
    case "SET_EXPERIENCE":
      return {
        ...state,
        experiences: action.data,
      };
    case "SET_RESUME":
      return {
        ...state,
        resume: action.data,
      };
    default:
      return state;
  }
}
