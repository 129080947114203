import React, { useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import Loader from "components/Loading";
// import Header from "components/Navbar";
// import Home from "./pages/Home";
// import NotFound from "./pages/Error404";
// import Detail from "./pages/Detail";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";

const Home = lazy(() => import("./pages/Home"));
const NotFound = lazy(() => import("./pages/Error404"));
const Detail = lazy(() => import("./pages/Detail"));

const Admin = lazy(() => import("./pages/Dashboard/Pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard/Pages/Main"));
const DashboardAbout = lazy(() => import("./pages/Dashboard/Pages/About"));

function App() {
  const { showNavbar } = useSelector((state) => state.home);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      startEvent: "load",
    });
  }, []);

  return (
    <Router>
      <div className={showNavbar ? "mobile-nav-active" : ""}>
        <Suspense fallback={<Loader />}>
          <Switch>
            {/* DASHBOARD */}
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/dashboard/about" component={DashboardAbout} />

            {/* SHOWCASE */}
            <Route exact path="/detail/:id" component={Detail} />
            <Route exact path="/" component={Home} />
            {/* NOT FOUND */}
            <Route exact path="/*" component={NotFound} />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
