const defaultValue = {
  user: null,
  id: null,
};

export default function contactReducer(state = defaultValue, action) {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        contactDescription: action.data,
      };
    default:
      return state;
  }
}
