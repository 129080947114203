const defaultValue = {
  portfolioDescription: `This is also my quick portfolio, for detail, please click the image. <br /> Some of my portofolio created when I'am still on
  bootcamp (bootcamp challenge), and the others is real project with my team on 
  my company (mostly I cant give password due to for internal use)`,
  portfolios: [
    {
      name: "Klob.id",
      imgUrl: "https://i.imgur.com/BH8ac3d.jpg",
      imgList: [
        "https://i.imgur.com/BH8ac3d.jpg",
        "https://i.imgur.com/eTyfmB5.jpg",
        "https://i.imgur.com/oiHbgBZ.jpg",
      ],
      linkUrl: "https://www.klob.id/",
      category: "Web app",
      client: "Klob",
      description:
        "Klob is a medium that aims to open doors of opportunity for Klobbers to find the ideal future. Through the features we provide, Klob is committed to creating access to information and equal opportunities for all parties to develop and succeed.",
    },
    {
      name: "Klob for Employers",
      imgUrl: "https://i.imgur.com/WAJZciq.jpg",
      imgList: [
        "https://i.imgur.com/WAJZciq.jpg",
        "https://i.imgur.com/6VcF367.jpg",
        "https://i.imgur.com/TPhxrb6.jpg",
      ],
      linkUrl: "https://employers.klob.id/",
      category: "Web app",
      client: "Klob",
      description:
        "Klob app for employers, employers can post job posting, collect data from job seeker, even can make online jobfair",
    },
    {
      name: "Intelligo",
      imgUrl: "https://i.imgur.com/xYD2iUH.png",
      imgList: [
        "https://i.imgur.com/xYD2iUH.png",
        "https://i.imgur.com/tiPEYmm.jpg",
        "https://i.imgur.com/X8eOUtx.jpg",
      ],
      linkUrl: "https://intelligo.klob.id/",
      category: "Web app",
      client: "Intelligo",
      description:
        "Landing website for Intelligo, a innovative learning bootcamp. <br />please feel free to visit the web page using link above. :)",
    },
    {
      name: "Intelligo for Hiring",
      imgUrl: "https://i.imgur.com/NJzPklK.png",
      imgList: [
        "https://i.imgur.com/NJzPklK.png",
        "https://i.imgur.com/TzRtW5p.jpg",
        "https://i.imgur.com/r9eLKu2.jpg",
      ],
      linkUrl: "https://hire.intelligo.klob.id/",
      category: "Web app",
      client: "Intelligo",
      description: `Web app for hiring partner, they can use this portal for search and recruit candidate <br />
      <br />
      *Sorry, this is internal web, so I can't give access to login
      `,
    },
    {
      name: "Intelligo CMS for admin",
      imgUrl: "https://i.imgur.com/h0QsVCe.png",
      imgList: [
        "https://i.imgur.com/h0QsVCe.png",
        "https://i.imgur.com/EGXtdKo.jpg",
        "https://i.imgur.com/vyz9DtE.jpg",
      ],
      linkUrl: "https://admin.intelligo.klob.id/",
      category: "Web app",
      client: "Intelligo",
      description: `CMS for admin intelligo, for setting and input data on intelligo & intelligo for hiring <br />
      <br />
      *Sorry, this is internal web, so I can't give access to login. click image for zooming and check the project.
        `,
    },
    {
      name: "Onion Nime",
      imgUrl: "https://i.imgur.com/Msx3vFQ.jpg",
      imgList: [
        "https://github.com/diardomarendikrista/onion-nime/raw/main/assets/page1.png",
        "https://github.com/diardomarendikrista/onion-nime/raw/main/assets/page2.png",
        "https://i.imgur.com/Msx3vFQ.jpg",
      ],
      linkUrl: "https://onion-nime.web.app/",
      category: "Web app",
      client: "Bootcamp Project",
      description:
        "A simple website to serve API from other server, in this case I using kitsunime API, including their search, detail and pagination.",
    },
    {
      name: "Petek Ragnarok Online",
      imgUrl: "https://i.imgur.com/zNiOOYK.jpg",
      imgList: [
        "https://i.imgur.com/zNiOOYK.jpg",
        "https://i.imgur.com/bmkGc2l.jpg",
      ],
      linkUrl: "https://petekragnarok.web.app/",
      category: "Website",
      client: "Myself & Community",
      description: `Website for display information about Ragnarok Online Private Server, from banner video, spesifications, how to install, etc.<br />
        By the way, this private server is host by me, if you know this game and feel nostalgic, feel free to play on this my private server.<br />
        I hope can host the server for a long time, because this is using my private unused old PC on my house.`,
    },
    {
      name: "Nitokomura",
      imgUrl: "https://i.imgur.com/smtESZu.jpg",
      imgList: [
        "https://i.imgur.com/smtESZu.jpg",
        "https://i.imgur.com/9n87FQI.jpg",
        "https://i.imgur.com/AVkXvgH.jpg",
      ],
      linkUrl: "https://nitokomura.web.app",
      category: "Web app",
      client: "Bootcamp Project",
      description:
        "Online shop that display banner, product and with cart feature. I also apply a checkout feature just for testing web history and decreasing of quantity product, not yet implement midtrans payment gateway.",
    },
    {
      name: "Nitokomura CMS",
      imgUrl: "https://i.imgur.com/lLNwSGA.jpg",
      imgList: [
        "https://i.imgur.com/22yu1ZY.jpg",
        "https://i.imgur.com/lLNwSGA.jpg",
        "https://i.imgur.com/Tty7gkz.jpg",
      ],
      linkUrl: "https://diardo-ecommerce-cms.web.app/",
      category: "Web app",
      client: "Bootcamp Project",
      description: `This is a CMS for Online Shop Nitokomura, on this web app, 
      admin can input product (name, price, image, etc) and also can setting a banner for website <br />
      <br />
      Feel free to try (please be responsible)<br />
      user : admin@mail.com <br />
      pass : 1234<br />
      `,
    },
    {
      name: "Night Kanban",
      imgUrl: "https://i.imgur.com/pUiXFpS.jpg",
      imgList: [
        "https://i.imgur.com/j66CUWQ.png",
        "https://i.imgur.com/pUiXFpS.jpg",
        "https://i.imgur.com/xtCeq1U.png",
      ],
      linkUrl: "https://diardo-night-kanban.web.app/",
      category: "Web app",
      client: "Bootcamp Project",
      description: `A simple draggable Kanban, I implement a library for drag and drop, 
      authorization for task owner, and implement social login (google login)<br />
      <br />
      *You can login by register or one-click login using google
      `,
    },
    {
      name: "Zoomie",
      imgUrl:
        "https://github.com/Zoomie-Istanbul/Zoomie-Client/raw/development/assets/screenshoots/screenshoot1.jpg",
      imgList: [
        "https://github.com/Zoomie-Istanbul/Zoomie-Client/raw/development/assets/screenshoots/screenshoot1.jpg",
        "https://github.com/Zoomie-Istanbul/Zoomie-Client/raw/development/assets/screenshoots/screenshoot2.jpg",
      ],
      linkUrl: "https://expo.io/@diardo/projects/zoomie",
      category: "Mobile app (React-Native)",
      client: "Bootcamp Project",
      description:
        "A application to connect repairer garage and people, so they can know where repairer garage in their city. Also implement with live chat feature, booking feature, price estimate from repairer, and even rating for repairer garage.",
    },
    {
      name: "Pokedex",
      imgUrl: "https://i.imgur.com/tQIv2lH.jpg",
      imgList: [
        "https://i.imgur.com/tQIv2lH.jpg",
        "https://github.com/diardomarendikrista/pokedex/raw/main/assets/web.png",
        "https://github.com/diardomarendikrista/pokedex/raw/main/assets/mobile.png",
      ],
      linkUrl: "https://diardo-pokedex.web.app/",
      category: "Web app",
      client: "Self Exercise",
      description:
        "A simple pokemon list, using Vue JS, implement auto-search complete, and link to pokemon's evolution",
    },
    {
      name: "Catch the Pokemon",
      imgUrl: "https://i.imgur.com/V9oRrMz.jpg",
      imgList: [
        "https://i.imgur.com/SLMO2ne.jpg",
        "https://i.imgur.com/V9oRrMz.jpg",
        "https://i.imgur.com/mBaOjl1.jpg",
      ],
      linkUrl: "https://diardo-pokemon.web.app/",
      category: "Web app",
      client: "Self Exercise",
      description:
        "A simple pokemon list, using Poke API and React JS, implement simple backend using Node JS, and can capture pokemon (with 50/50 chance), and save them in postgreSQL database",
    },
  ],
};

export default function portfolioReducer(state = defaultValue, action) {
  switch (action.type) {
    case "SET_PORTFOLIO":
      return {
        ...state,
        portfolios: action.data,
      };
    default:
      return state;
  }
}
